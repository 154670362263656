import { apiClient } from '../../../helpers/apiHelperV2';
import { FETCH_PREVIEW_FAILURE, FETCH_PREVIEW_REQUEST, FETCH_PREVIEW_SUCCESS } from '../../actionTypes';
import { previewCategoriesToKey, previewResponseSelector } from '../../selectors/standort/preview';
import { shouldFetch } from '../../fetchStatus';
import { standortUrlSelector } from '../../selectors/url';

export const fetchPreviewIfNeeded = ({ previewCategories }) => {
    return async function (dispatch, getState) {
        const state = getState();

        const standortUrl = standortUrlSelector(state);
        const previewResponse = previewResponseSelector(state, { previewCategories });
        const previewCategoriesKey = previewCategoriesToKey(previewCategories);

        if (shouldFetch(previewResponse.status)) {
            dispatch({
                type: FETCH_PREVIEW_REQUEST,
                payload: { standortUrl, previewCategories, previewCategoriesKey },
            });
            try {
                const res = await apiClient.get('/api/v1/Preview', {
                    params: {
                        categories: previewCategories,
                        standortUrl,
                    },
                });
                dispatch({
                    type: FETCH_PREVIEW_SUCCESS,
                    payload: { standortUrl, previewCategories, previewCategoriesKey, preview: res.data.message },
                });
            } catch (error) {
                dispatch({
                    type: FETCH_PREVIEW_FAILURE,
                    payload: { standortUrl, previewCategories, previewCategoriesKey, error },
                });
            }
        }
    };
};
