import { createSelector } from 'reselect';
import _ from 'lodash';
import { pendingResponse } from '../../fetchStatus';
import { standortStateSelector } from '../standort';
import { extraParamsSelector } from '../util';

// Shim if previewCategories should be ever derived from state/props
const previewCategoriesKeySelector = createSelector([extraParamsSelector], params => {
    const { previewCategories } = params;

    if (!_.isArray(previewCategories)) {
        throw new Error(`Expected previewCategories to be an array, instead got: ${previewCategories}`);
    }
    return previewCategoriesToKey(previewCategories);
});

export const previewCategoriesToKey = previewCategories => previewCategories.join('&');

const previewsSelector = createSelector([standortStateSelector], standortState => standortState.previews);

export const previewResponseSelector = createSelector(
    [previewsSelector, previewCategoriesKeySelector],
    (previews, previewCategoriesKey) => previews?.[previewCategoriesKey] ?? pendingResponse
);

export const previewSelector = createSelector([previewResponseSelector], previewResponse => _.get(previewResponse, 'data'));
